import { matchPath } from "react-router";
import { api } from "data";
import {
  MANAGE_OFFERINGS_ROUTE_IDS,
  MANAGE_OFFERINGS_ROUTE_KEYS,
} from "features/ManageOfferings/constants/ManageOfferings.urlPaths";

export const offering_feedback_resource_apis = {
  [MANAGE_OFFERINGS_ROUTE_IDS[MANAGE_OFFERINGS_ROUTE_KEYS.OFFERING_FEEDBACK]]:
    ({ pathname }) => {
      const matchResults = matchPath(pathname, {
        path: MANAGE_OFFERINGS_ROUTE_IDS[
          MANAGE_OFFERINGS_ROUTE_KEYS.OFFERING_FEEDBACK
        ],
      });
      return {
        api_end_point: api.feedbacks.fetch_feedbacks_list,
        queries: {
          listing__uuid: matchResults?.params?.listing_uuid,
        },
      };
    },
};
