import { downloadImage } from "features/Common/utils/media.utils";
import { useListingsStore } from "features/Listings/store/useListingsStore";
import { CREATIVE_ID } from "../constants/ShareListingPopup.constants";
import { notification_color_keys, useNotifications } from "utils/hooks";
import {
  CHECKOUT_PAGE_ROUTE_KEYS,
  CHECKOUT_PAGES_ROUTE_GENERATORS,
} from "features/CheckoutPages/constants/CheckoutPages.constants";
import { useHistory } from "react-router-dom";
import { copyToClipboard } from "features/Common/utils/Copy.utils";

export const useShareListingPopup = () => {
  const { notify } = useNotifications();
  const history = useHistory();

  const shareListingPopupOpen = useListingsStore(
    (state) => state.shareListingPopupOpen
  );

  const setShareListingPopupOpen = useListingsStore(
    (state) => state.setShareListingPopupOpen
  );

  const openShareListingPopup = () => {
    setShareListingPopupOpen(true);
  };

  const closeShareListingPopup = () => {
    setShareListingPopupOpen(false);
  };

  const handleClose = ({ onClose }) => {
    onClose();
    closeShareListingPopup();
  };

  const handleCopyLink = ({ link }) => {
    copyToClipboard(link);
    notify("Link Copied!", notification_color_keys.info);
  };

  const handlePaymentPageButtonClick = ({
    offeringId,
    dynamicLinkUuid,
    isCheckoutPage,
  }) => {
    if (dynamicLinkUuid) {
      history.push(
        `${CHECKOUT_PAGES_ROUTE_GENERATORS[
          CHECKOUT_PAGE_ROUTE_KEYS.checkout_page_update_details
        ]({
          listingUuid: offeringId,
          dynamicLinkUuid,
          isCheckout: isCheckoutPage,
        })}`
      );
    }
  };

  const handleCreativeClick = () =>
    downloadImage({
      id: CREATIVE_ID,
      onStart: () =>
        notify("Your download has started.", notification_color_keys.info),
    });

  return {
    shareListingPopupOpen,
    openShareListingPopup,
    closeShareListingPopup,
    handleCopyLink,
    handlePaymentPageButtonClick,
    handleCreativeClick,
    handleClose,
  };
};
