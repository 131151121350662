import { useManageOfferingsStore } from "features/ManageOfferings/store/useManageOfferingsStore";

export const useOfferingFeedbackSettingsModal = () => {
  const setOfferingFeedbackSettingsModalOpen = useManageOfferingsStore(
    (state) => state.setOfferingFeedbackSettingsModalOpen
  );
  const offeringFeedbackSettingsModalOpen = useManageOfferingsStore(
    (state) => state.offeringFeedbackSettingsModalOpen
  );
  const openOfferingFeedbackSettingsModal = () => {
    setOfferingFeedbackSettingsModalOpen(true);
  };
  const closeOfferingFeedbackSettingsModal = () => {
    setOfferingFeedbackSettingsModalOpen(false);
  };
  return {
    offeringFeedbackSettingsModalOpen,
    openOfferingFeedbackSettingsModal,
    closeOfferingFeedbackSettingsModal,
  };
};
