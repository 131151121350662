import AutorenewIcon from "@material-ui/icons/Autorenew";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import React from "react";

export const SAVING_STATUS = {
  SAVING: 1,
  SAVED: 2,
  ERROR: 3,
};

export const SAVING_STATUS_ICONS = {
  [SAVING_STATUS.SAVING]: (
    <AutorenewIcon className="!tw-animate-spin !tw-mt-[-2px] !tw-mr-1 max max-md:!tw-text-sm" />
  ),
  [SAVING_STATUS.SAVED]: (
    <>
      <CheckCircleRoundedIcon className="!tw-mt-[-2px] !tw-mr-1 max max-md:!tw-text-sm max-md:!tw-hidden" />
      <CheckRoundedIcon className="!tw-mt-[-2px] !tw-mr-1 max max-md:!tw-text-sm md:!tw-hidden" />
    </>
  ),
  [SAVING_STATUS.ERROR]: (
    <>
      <ErrorRoundedIcon className="!tw-mt-[-2px] !tw-mr-1 max max-md:!tw-text-sm max-md:!tw-hidden" />
      <ErrorOutlineRoundedIcon className="!tw-mt-[-2px] !tw-mr-1 max max-md:!tw-text-sm md:!tw-hidden" />
    </>
  ),
};

export const SAVING_STATUS_MESSAGES = {
  [SAVING_STATUS.SAVING]: "Saving...",
  [SAVING_STATUS.SAVED]: "Saved",
  [SAVING_STATUS.ERROR]: "Couldn’t save. Please try again.",
};

export const SAVING_STATUS_CLASSES = {
  [SAVING_STATUS.SAVING]:
    "tw-text-[#00A540] max-md:tw-text-[#008734] max-md:[background:linear-gradient(90deg,_#D8EEE0_0%,_#E4F5EA_50%,_#D8EEE0_100%)]",
  [SAVING_STATUS.SAVED]:
    "tw-text-[#00A540] max-md:tw-bg-[#00B779] max-md:tw-text-white",
  [SAVING_STATUS.ERROR]:
    "tw-text-[#BF483B] max-md:tw-bg-[#BF483B] max-md:tw-text-white",
};

export const STATUS_FADE_DURATION = 200;
export const STATUS_VISIBLE_DURATION_WITHOUT_ANIMATION = 1500;
export const STATUS_VISIBLE_TIME =
  STATUS_VISIBLE_DURATION_WITHOUT_ANIMATION + STATUS_FADE_DURATION;
