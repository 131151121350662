import React from "react";
import { SettingsOutlined } from "@material-ui/icons";
import { Button } from "@my-scoot/component-library-legacy";
import { useOfferingFeedbackSettingsModal } from "../OfferingFeedbackSettingsModal/utils/useOfferingFeedbackSettingsModal";
import { LazyLoadWrapper } from "features/Common/LazyLoadWrapper/LazyLoadWrapper";
import { OfferingFeedbackSettingsModalForm } from "../OfferingFeedbackSettingsModal/OfferingFeedbackSettingsModalForm";
import { useDesktopMediaQuery } from "features/Common/hooks/useDesktopMediaQuery";
import { useAdminPanelStore } from "features/AdminPanel/store/useAdminPanelStore";
import { FEATURE_STATUS } from "features/Account/Account.constants";
import { FEEDBACK_SETTINGS_KEY } from "../../constants/OfferingFeedback.constants";

export const OfferingFeedbackSettingsBtn = ({
  fullWidth = false,
  buttonWrapperClassName,
}) => {
  const sectionConfig = useAdminPanelStore((state) => state.sectionConfig);
  const subSectionConfig = sectionConfig[FEEDBACK_SETTINGS_KEY] || {};
  const { status } = subSectionConfig;
  const hideFeedbackSettings = status === FEATURE_STATUS.HIDDEN;

  const {
    openOfferingFeedbackSettingsModal,
    offeringFeedbackSettingsModalOpen,
  } = useOfferingFeedbackSettingsModal();

  const isDesktop = useDesktopMediaQuery();
  if (hideFeedbackSettings) return <></>;
  return (
    <>
      <Button
        color="primary"
        size="medium"
        fullWidth={fullWidth}
        onClick={openOfferingFeedbackSettingsModal}
        className={`${isDesktop ? "!tw-w-[129px]" : ""} tw-text-white w-100`}
        buttonWrapperClassName={`tw-flex tw-items-center tw-text-white ${
          isDesktop && "tw-ml-3"
        } ${!isDesktop && "tw-mb-[32px]"} ${buttonWrapperClassName}`}
      >
        <SettingsOutlined className="tw-w-[18px] tw-h-[18px] tw-mr-1" />
        Settings
      </Button>
      <LazyLoadWrapper loadingCondition={offeringFeedbackSettingsModalOpen}>
        <OfferingFeedbackSettingsModalForm />
      </LazyLoadWrapper>
    </>
  );
};
