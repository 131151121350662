import React from "react";
import { Route } from "react-router-dom";
import { LazyLoadRoutes } from "routes/lazyLoadRoutes";

const VisibilityAvailability = React.lazy(() =>
  import(
    "features/ManageOfferings/modules/VisibilityAvailability/VisibilityAvailability"
  ).then((module) => ({
    default: module.VisibilityAvailability,
  }))
);

const PixelTracking = React.lazy(() =>
  import("features/ManageOfferings/modules/PixelTracking/PixelTracking").then(
    (module) => ({
      default: module.PixelTracking,
    })
  )
);

const OfferingPageSections = React.lazy(() =>
  import(
    "features/ManageOfferings/modules/OfferingPageSections/OfferingPageSections"
  ).then((module) => ({
    default: module.OfferingPageSections,
  }))
);

export const MANAGE_OFFERINGS_ROUTE_KEYS = {
  VISIBILITY_AVAILABILITY: "VISIBILITY_AVAILABILITY",
  PIXEL_TRACKING: "PIXEL_TRACKING",
  OFFERING_FEEDBACK: "OFFERING_FEEDBACK",
  OFFERING_FEEDBACK_FORM: "OFFERING_FEEDBACK_FORM",
  OFFERING_PUBLISHED_FEEDBACK: "OFFERING_PUBLISHED_FEEDBACK",
  PAGE_SECTIONS: "PAGE_SECTIONS",
  FAQS: "FAQS",
  MANAGE_OFFERING: "manage_offering",
};

export const MANAGE_OFFERINGS_ROUTE_IDS = {
  [MANAGE_OFFERINGS_ROUTE_KEYS.VISIBILITY_AVAILABILITY]:
    "/visibility-availability/:listing_uuid",
  [MANAGE_OFFERINGS_ROUTE_KEYS.PIXEL_TRACKING]:
    "/purchase-pixel-tracking/:listing_uuid",
  [MANAGE_OFFERINGS_ROUTE_KEYS.OFFERING_FEEDBACK]:
    "offering-user-feedbacks/:listing_uuid",
  [MANAGE_OFFERINGS_ROUTE_KEYS.OFFERING_FEEDBACK_FORM]:
    "offering-feedback-form/:listing_uuid",
  [MANAGE_OFFERINGS_ROUTE_KEYS.OFFERING_PUBLISHED_FEEDBACK]:
    "offering-published-form/:listing_uuid",
  [MANAGE_OFFERINGS_ROUTE_KEYS.PAGE_SECTIONS]:
    "/offering-page-sections/:listing_uuid",
  [MANAGE_OFFERINGS_ROUTE_KEYS.FAQS]: "offering-faqs/:listing_uuid",
};

export const MANAGE_OFFERINGS_ROUTE_PATHNAMES = {
  [MANAGE_OFFERINGS_ROUTE_KEYS.OFFERING_PUBLISHED_FEEDBACK]: ({
    listing_uuid,
    section_key,
    navigate_back = false,
  }) =>
    `offering-published-form/${listing_uuid}?section_key=${section_key}&navigate_back=${navigate_back}`,
  [MANAGE_OFFERINGS_ROUTE_KEYS.OFFERING_FEEDBACK_FORM]: ({
    listing_uuid,
    section_key,
  }) => `offering-feedback-form/${listing_uuid}?section_key=${section_key}`,
  [MANAGE_OFFERINGS_ROUTE_KEYS.FAQS]: ({ listing_uuid }) =>
    `offering-faqs/${listing_uuid}`,
  [MANAGE_OFFERINGS_ROUTE_KEYS.MANAGE_OFFERING]: ({
    listingUuid,
    utmParams = {},
  }) => {
    const basePath = `/manage-offering/${listingUuid}`;

    // Add UTM parameters if provided
    const utmString = new URLSearchParams(utmParams).toString();

    return utmString ? `${basePath}?${utmString}` : basePath;
  },
};

export const MANAGE_OFFERINGS_CUSTOM_ROUTES = [
  <Route
    exact
    key={
      MANAGE_OFFERINGS_ROUTE_IDS[
        MANAGE_OFFERINGS_ROUTE_KEYS.VISIBILITY_AVAILABILITY
      ]
    }
    path={
      MANAGE_OFFERINGS_ROUTE_IDS[
        MANAGE_OFFERINGS_ROUTE_KEYS.VISIBILITY_AVAILABILITY
      ]
    }
    render={(props) => (
      <LazyLoadRoutes LazyElement={VisibilityAvailability} props={props} />
    )}
  />,
  <Route
    exact
    key={MANAGE_OFFERINGS_ROUTE_IDS[MANAGE_OFFERINGS_ROUTE_KEYS.PIXEL_TRACKING]}
    path={
      MANAGE_OFFERINGS_ROUTE_IDS[MANAGE_OFFERINGS_ROUTE_KEYS.PIXEL_TRACKING]
    }
    render={(props) => (
      <LazyLoadRoutes LazyElement={PixelTracking} props={props} />
    )}
  />,
  <Route
    exact
    key={MANAGE_OFFERINGS_ROUTE_IDS[MANAGE_OFFERINGS_ROUTE_KEYS.PAGE_SECTIONS]}
    path={MANAGE_OFFERINGS_ROUTE_IDS[MANAGE_OFFERINGS_ROUTE_KEYS.PAGE_SECTIONS]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={OfferingPageSections} props={props} />
    )}
  />,
];
