import React, { useEffect } from "react";
import {
  creatorToolTheme,
  ThemeProvider,
  Tooltip,
} from "@my-scoot/component-library-legacy";
import useStyles from "../../../ui/layout/AppBar/AppBar.styles";
import { getAuthData } from "../../../utils/AuthUtil";
import { is_empty } from "../../Common/utils/common.utils";
import { ReactComponent as AnnouncementIcon } from "assets/vectors/campaign.svg";
import { useDesktopMediaQuery } from "../../Common/hooks/useDesktopMediaQuery";

const FeatureAnnouncementBadge = ({ width, height, color }) => {
  // Use component state to show the number of unread announcements
  const [unreadAnnouncementCount, setUnreadAnnouncementCount] =
    React.useState(0);

  // We need to keep a reference to the widget instance so we interact with it
  const widgetRef = React.useRef(null);
  const classes = useStyles();
  const isDesktop = useDesktopMediaQuery("(min-width: 767px)");
  const widgetKey = process.env.REACT_APP_FRILL_ANNOUNCEMENT_MODAL_WIDGET_KEY;

  function handleLinkClick() {
    if (is_empty(widgetKey)) {
      return;
    }

    // First check that there is a widget
    if (widgetRef.current) {
      // Mark all announcements as read so the notification badge won't appear again
      // until there are new announcements
      widgetRef.current.markAsRead();
    }
    window.open(`${window.location.origin}/#/announcements`, "_blank").focus();
  }

  useEffect(() => {
    const auth = getAuthData();

    if (
      !window.Frill ||
      is_empty(auth?.email) ||
      is_empty(auth?.sub_domain) ||
      is_empty(widgetKey)
    ) {
      return;
    }

    // Initialize the embedded widget
    const widget = window.Frill("widget", {
      key: process.env.REACT_APP_FRILL_ANNOUNCEMENT_MODAL_WIDGET_KEY,
      user: { email: auth.email, name: auth?.sub_domain },
      callbacks: {
        onReady(widget) {
          widgetRef.current = widget;
        },

        onBadgeCount({ count }) {
          setUnreadAnnouncementCount(count);
          // The event also includes the unread announcements. Each object in the list includes
          // the announcement idx, slug & published_at properties.
          if (count > 0 && widgetRef.current && isDesktop) {
            widgetRef.current.open();
          }
        },
      },
    });

    return () => {
      if (widget && widget.destroy) {
        widget.destroy();
      }
    };
  }, []);

  // This is a link to view all announcements on the frill platform
  return (
    <ThemeProvider theme={creatorToolTheme}>
      <Tooltip arrow color="primary" title={isDesktop ? "Announcements" : ""}>
        <span
          className={classes.notification_wrapper}
          onClick={handleLinkClick}
        >
          {unreadAnnouncementCount > 0 ? (
            <span className={classes.redDot}>{unreadAnnouncementCount}</span>
          ) : (
            <></>
          )}
          <AnnouncementIcon style={{ width, height, color }} />
        </span>
      </Tooltip>
    </ThemeProvider>
  );
};

export default FeatureAnnouncementBadge;
