import { matchPath } from "react-router";
import { api } from "data";
import {
  MANAGE_OFFERINGS_ROUTE_IDS,
  MANAGE_OFFERINGS_ROUTE_KEYS,
} from "features/ManageOfferings/constants/ManageOfferings.urlPaths";

export const offering_faqs_resource_apis = {
  [MANAGE_OFFERINGS_ROUTE_IDS[MANAGE_OFFERINGS_ROUTE_KEYS.FAQS]]: ({
    pathname,
  }) => {
    const matchResults = matchPath(pathname, {
      path: MANAGE_OFFERINGS_ROUTE_IDS[MANAGE_OFFERINGS_ROUTE_KEYS.FAQS],
    });
    return {
      api_end_point: api.faq_list,
      queries: {
        listing__uuid: matchResults?.params?.listing_uuid,
      },
    };
  },
};
