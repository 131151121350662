import React from "react";
import Leaderboard from "ui/pages/Leaderboard/Leaderboard";
import { MyAdminPanelWrapper } from "features/AdminPanel/modules/MyAdminPanelWrapper/MyAdminPanelWrapper";
import { useParams } from "react-router-dom";
import {
  MANAGE_OFFERINGS_FEATURE_KEY,
  MANAGE_OFFERINGS_HEADER_TITLE,
} from "features/ManageOfferings/constants/ManageOfferings.constants";

export const LeaderboardWrapper = (props) => {
  const { listing_uuid } = useParams();

  return (
    <MyAdminPanelWrapper
      featureKey={`${MANAGE_OFFERINGS_FEATURE_KEY}/${listing_uuid}`}
      classes={{ childrenComponentStyle: "bg-transparent !tw-p-0" }}
      headerTitle={MANAGE_OFFERINGS_HEADER_TITLE}
    >
      <Leaderboard {...props} />
    </MyAdminPanelWrapper>
  );
};
