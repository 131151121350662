import React from "react";
import { MyAdminPanelWrapper } from "features/AdminPanel/modules/MyAdminPanelWrapper/MyAdminPanelWrapper";
import { EXLY_FEEDBACKS_MORE_HREFS } from "ui/pages/ExlyFeedbacks/data/module_constants";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import { useDesktopMediaQuery } from "features/Common/hooks/useDesktopMediaQuery";
import { useParams } from "react-router-dom";
import { MANAGE_OFFERINGS_FEATURE_KEY } from "features/ManageOfferings/constants/ManageOfferings.constants";
import ExlyFaq from "ui/pages/manageWebpage/FAQ";

export const OfferingFaqs = (props) => {
  const isDesktop = useDesktopMediaQuery();
  const { listing_uuid } = useParams();

  return (
    <MyAdminPanelWrapper
      featureKey={`${MANAGE_OFFERINGS_FEATURE_KEY}/${listing_uuid}`}
      classes={{ childrenComponentStyle: "bg-transparent pt-0" }}
      hideHeader={false}
      {...(!isDesktop
        ? {
            headerTitle: "FAQs",
            headerRight: (
              <LearnMoreCta href={EXLY_FEEDBACKS_MORE_HREFS.FEEDBACK} />
            ),
          }
        : {})}
    >
      <ExlyFaq
        {...props}
        offeringLevel={true}
        pageHeadClassName={
          isDesktop
            ? "!tw-pb-[16px] top-0 !tw-mb-[24px] !tw-pt-[40px] tw-border-b tw-border-[#e8e8e8]"
            : "top-0"
        }
      />
    </MyAdminPanelWrapper>
  );
};
