import React from "react";
import { Route } from "react-router-dom";
import { LazyLoadRoutes } from "routes/lazyLoadRoutes";

const Integration = React.lazy(() =>
  import("features/Integration/Integration")
);

const IntegrationDetails = React.lazy(() =>
  import("features/Integration/modules/IntegrationDetails/IntegrationDetails")
);

export const INTEGRATION_ROUTE_KEYS = {
  integration: "integration_page",
  integration_details: "integration_details",
};

export const INTEGRATION_ROUTE_IDS = {
  [INTEGRATION_ROUTE_KEYS.integration]: "/Integrations",
  [INTEGRATION_ROUTE_KEYS.integration_details]: "/Integrations/:integrationId",
};

export const INTEGRATION_ROUTE_GENERATORS = {
  /**
   * @param {object} param0
   * @param {string} param0.slug - refer `integrations_cards` in `src/features/Integration/constants/Integration.constants`
   */
  [INTEGRATION_ROUTE_KEYS.integration_details]: ({ slug }) => {
    return `${
      INTEGRATION_ROUTE_IDS[INTEGRATION_ROUTE_KEYS.integration]
    }/${slug}`;
  },
};

export const INTEGRATION_CUSTOM_ROUTES = [
  <Route
    exact
    key={INTEGRATION_ROUTE_IDS[INTEGRATION_ROUTE_KEYS.integration]}
    path={INTEGRATION_ROUTE_IDS[INTEGRATION_ROUTE_KEYS.integration]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={Integration} props={props} />
    )}
  />,
  <Route
    exact
    key={INTEGRATION_ROUTE_IDS[INTEGRATION_ROUTE_KEYS.integration_details]}
    path={INTEGRATION_ROUTE_IDS[INTEGRATION_ROUTE_KEYS.integration_details]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={IntegrationDetails} props={props} />
    )}
  />,
];
