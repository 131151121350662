import React from "react";
import { meta_url } from "../../WhatsappStepper/modules/WhatsappStepperStepTwo/constants/WhatsappStepperStepTwo.constants";
import { whitelabelled_whatsapp_status_constants } from "ui/pages/whatsappBroadcast/whatsappBroadcast.constants";

export const whatsapp_overview_constant = {
  title: "Overview",
  subTitle:
    "Communicating with clients is now just as simple as sending a quick WhatsApp message. However, having a WhatsApp business account allows you to create a business profile, including your location, website link, hours of operation, and more. This allows your company to have a professional image when contacting clients through WhatsApp.",
};

export const whatsapp_installation_constant = {
  title: "Installation",
};

export const options = {
  guide: {
    value: 0,
    label: "Guide",
  },
  setup: {
    value: 1,
    label: "Setup",
  },
};

export const whatsappSteps = {
  connectMeta: 0,
  goToBusinessManager: 1,
  allStepsDone: 2,
};

export const WA_LEARN_MORE_HREFS = {
  EXISTING_NUMBER:
    "https://support.exlyapp.com/support/solutions/articles/84000396612",
  DISPLAY_NAME:
    "https://support.exlyapp.com/support/solutions/articles/84000396613",
  META_BUSINESS_MANAGER_VERIFY_GUIDE:
    "https://www.facebook.com/business/help/2058515294227817?id=180505742745347",
  TEMPLATES:
    "https://developers.facebook.com/docs/whatsapp/message-templates/guidelines/",
  META_BUSINESS_MANAGER_LOGIN: meta_url,
};

export const whatsapp_overview_points = [
  {
    title: "After integrating your WhatsApp number with Exly, you can:",
    points: [
      <>
        Create personalised templates on WhatsApp and use them for your one-time
        and automated campaigns.
      </>,
      <>
        Send WhatsApp messages to your clients with your own number and under
        your own brand name.
      </>,
      <>Respond to unlimited customer initiated conversations.</>,
    ],
  },
];

export const whatsapp_installation_points = [
  {
    title: "How do you connect your WhatsApp number?",
    subTitle:
      "Key details to check before you get started. You’ll need to create a WhatsApp Business Account and connect your Meta Business Manager. Make sure you have these ready:",
    points: [
      <>Your company’s legal name and address</>,
      <>
        A display name and short business description.{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href={WA_LEARN_MORE_HREFS.DISPLAY_NAME}
        >
          Learn more
        </a>
      </>,
      <>A phone number that does not have an existing WhatsApp account.</>,
    ],
    disclaimer: {
      icon: true,
      disclaimerText:
        "Do not use a number that’s already connected to WhatsApp, as Meta requires a number that is not connected to any WhatsApp account.",
    },
  },
];

export const whatsapp_after_installation_points = [
  {
    title: "What will happen while your setup is in progress?",
    points: [
      <>
        The WhatsApp Business Account creation process might take up to{" "}
        <span className="tw-font-medium">1-2 hrs</span> and the time taken to
        verify your Meta Business Manager can vary from as little as{" "}
        <span className="tw-font-medium">10 minutes to 14 business days</span>.
      </>,
      <>
        After this we will be setting up all your previous WhatsApp templates
        (if any) and all WhatsApp automated messages for this number. This may
        take upto 24 hours.
      </>,
      <>
        <span className="tw-font-medium">Until verification</span>, all your
        WhatsApp communications will be sent{" "}
        <span className="tw-font-medium">via Exly’s number</span>
      </>,
      <>
        Once everything is set up, we’ll notify you via email. Thereon, all
        WhatsApp communications (all automated and any new messages created)
        will be sent from your own number.
      </>,
    ],
  },
  {
    title: "Things to note after you’ve connected your WhatsApp number",
    points: [
      <>
        The custom templates you had created earlier would be migrated to your
        personal number.{" "}
        <span className="tw-font-medium">
          The status of these templates is subject to Meta’s approval.
        </span>
      </>,
      <>
        In case any templates were rejected during migration, you would need to
        <span className="tw-font-medium">
          manually submit them again for approval
        </span>
        .
      </>,
      <>
        The templates you have used from the{" "}
        <span className="tw-font-medium">Template library</span> won’t be
        pre-approved during migration. You would need to submit them for
        approval manually.
      </>,
      <>
        Scheduled messages won’t be paused. They will be sent via Exly’s number.
      </>,
      <>
        All your previously-set{" "}
        <span className="tw-font-medium">Offering Specific Communications</span>{" "}
        will be sent via{" "}
        <span className="tw-font-medium">
          Exly’s number until you manually set them up
        </span>{" "}
        to be sent via your own number.
      </>,
    ],
  },
];

export const WA_SYNC_TYPES = {
  PROFILE: 1,
  WHATSAPP_TEMPLATES: 2,
  DISPLAY_NAME: 3,
  FB_VERIFY_STATUS: 4,
  WABA_CONNECT_STATUS: 5,
};

export const WA_LINKING_STATUS = {
  NOT_APPLIED: 1,
  IN_PROGRESS: 2,
  CONNECTED: 3,
};

export const WA_LINKING_STATUS_TEXTS = {
  NOT_STARTED: "Not Started",
  IN_PROGRESS: "In Progress",
  CONNECTED: "Connected",
};

export const WA_LINKING_STATUS_LABELS = {
  [whitelabelled_whatsapp_status_constants.FAILED]:
    WA_LINKING_STATUS_TEXTS.IN_PROGRESS,
  [whitelabelled_whatsapp_status_constants.APPROVED]:
    WA_LINKING_STATUS_TEXTS.IN_PROGRESS,
  [whitelabelled_whatsapp_status_constants.PENDING]:
    WA_LINKING_STATUS_TEXTS.IN_PROGRESS,
  [whitelabelled_whatsapp_status_constants.BLOCKED]:
    WA_LINKING_STATUS_TEXTS.IN_PROGRESS,
  [whitelabelled_whatsapp_status_constants.CONNECTED]:
    WA_LINKING_STATUS_TEXTS.CONNECTED,
};

export const WA_LINKING_API_KEYS = {
  exly_business_uuid: "exly_business_uuid",
  exly_project_uuid: "exly_project_uuid",
  wa_phone_number: "wa_phone_number",
  wa_country_code: "wa_country_code",
  fb_business_manager_status: "fb_business_manager_status",
  wa_display_name: "wa_display_name",
  wa_display_image: "wa_display_image",
  whitelabelled_whatsapp_status: "whitelabelled_whatsapp_status",
  has_migrated_to_aisensy: "has_migrated_to_aisensy",
  wa_display_name_status: "wa_display_name_status",
  wa_waba_connect_status: "wa_waba_connect_status",
  wa_template_status: "wa_template_status",
  wa_template_reject_count: "wa_template_reject_count",
  waba_failure_reason: "waba_failure_reason",
  is_business_email_changed: "is_business_email_changed",
};
