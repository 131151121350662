export const whatsapp_whitelabelled_status_keys = {
  success: "success",
  error: "error",
  warning: "warning",
};

export const ai_sensy_demo_video_url =
  "https://www.youtube.com/watch?v=G4Rwn53rqsk";
export const meta_url =
  "https://business.facebook.com/select/?next=https%3A%2F%2Fbusiness.facebook.com%2Fsettings%2Fsecurity&ext=1697033881&hash=AflL2xG_NZQYuLwzWq9f0TKjvnxR1RwTNLKigeUBdVbp1w";
