import React from "react";
import { MyAdminPanelWrapper } from "features/AdminPanel/modules/MyAdminPanelWrapper/MyAdminPanelWrapper";
import { useDesktopMediaQuery } from "features/Common/hooks/useDesktopMediaQuery";
import {
  MANAGE_OFFERINGS_FEATURE_KEY,
  MANAGE_OFFERINGS_HEADER_TITLE,
} from "features/ManageOfferings/constants/ManageOfferings.constants";
import { getManageOfferingRedirectUrl } from "features/ManageOfferings/utils/ManageOffering.utils";
import { useParams } from "react-router-dom";
import ManageRecordings from "ui/pages/schedule/ManageOffering/pages/ManageRecordings/ManageRecordings";

export const ManageUnmappedRecordingsWrapper = (props) => {
  const isDesktop = useDesktopMediaQuery();
  const { listing_uuid } = useParams();
  const backUrl = getManageOfferingRedirectUrl({ listingId: listing_uuid });

  return (
    <MyAdminPanelWrapper
      featureKey={`${MANAGE_OFFERINGS_FEATURE_KEY}/${listing_uuid}`}
      classes={{
        childrenComponentStyle: "bg-transparent !tw-p-0",
        ...(!isDesktop && { container: "px-3 top-0" }),
      }}
      headerTitle={
        !isDesktop
          ? "Manage unmapped recordings"
          : MANAGE_OFFERINGS_HEADER_TITLE
      }
      hideHeader={false}
      {...(!isDesktop
        ? {
            backUrl,
          }
        : {})}
    >
      <ManageRecordings {...props} />
    </MyAdminPanelWrapper>
  );
};
