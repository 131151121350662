import { matchPath } from "react-router";
import {
  MANAGE_OFFERINGS_ROUTE_IDS,
  MANAGE_OFFERINGS_ROUTE_KEYS,
} from "features/ManageOfferings/constants/ManageOfferings.urlPaths";
import { app_route_ids, app_route_keys } from "constants/urlPaths";

export const offering_published_feedbacks_resource_apis = {
  [MANAGE_OFFERINGS_ROUTE_IDS[
    MANAGE_OFFERINGS_ROUTE_KEYS.OFFERING_PUBLISHED_FEEDBACK
  ]]: ({ pathname }) => {
    const matchResults = matchPath(pathname, {
      path: MANAGE_OFFERINGS_ROUTE_IDS[
        MANAGE_OFFERINGS_ROUTE_KEYS.OFFERING_PUBLISHED_FEEDBACK
      ],
    });
    return {
      api_end_point: app_route_ids[app_route_keys.listing_reviews],
      queries: {
        listing__uuid: matchResults?.params?.listing_uuid,
      },
    };
  },
};
