import React from "react";
import { MyAdminPanelWrapper } from "features/AdminPanel/modules/MyAdminPanelWrapper/MyAdminPanelWrapper";
import { useDesktopMediaQuery } from "features/Common/hooks/useDesktopMediaQuery";
import {
  MANAGE_OFFERINGS_FEATURE_KEY,
  MANAGE_OFFERINGS_HEADER_TITLE,
} from "features/ManageOfferings/constants/ManageOfferings.constants";
import { getManageOfferingRedirectUrl } from "features/ManageOfferings/utils/ManageOffering.utils";
import { useParams } from "react-router-dom";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import { OfferingVariations } from "features/OfferingVariations";
import { OFFERING_VARIATIONS_LEARN_MORE_HREFS } from "features/OfferingVariations/pages/OfferingVariationsList/OfferingVariationsList.constants";

export const OfferingVariationsWrapper = (props) => {
  const isDesktop = useDesktopMediaQuery();
  const { offeringUuid } = useParams();
  const backUrl = getManageOfferingRedirectUrl({ listingId: offeringUuid });

  return (
    <MyAdminPanelWrapper
      featureKey={`${MANAGE_OFFERINGS_FEATURE_KEY}/${offeringUuid}`}
      classes={{
        childrenComponentStyle: "bg-transparent !tw-p-0",
        ...(!isDesktop && { container: "px-3 top-0" }),
      }}
      headerTitle={
        !isDesktop ? "Manage Variations" : MANAGE_OFFERINGS_HEADER_TITLE
      }
      hideHeader={false}
      {...(!isDesktop
        ? {
            backUrl,
            headerRight: (
              <LearnMoreCta
                href={OFFERING_VARIATIONS_LEARN_MORE_HREFS.OFFERING_VARTIATION}
              />
            ),
          }
        : {})}
    >
      <OfferingVariations {...props} />
    </MyAdminPanelWrapper>
  );
};
