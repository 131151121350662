import React, { useState } from "react";
import { FEEDBACK_SETTINGS_KEY } from "../../constants/OfferingFeedback.constants";
import { useAdminPanelStore } from "features/AdminPanel/store/useAdminPanelStore";
import { useParams } from "react-router-dom";
import { UPDATE_ADMIN_PANEL_SETTINGS_LISITNG_LEVEL } from "features/AdminPanel/constants/AdminPanel.api";
import { useAccountSectionUnlocking } from "features/Account/utils/useAccountSectionChange";
import { ExlyModal } from "features/Common/modules/ExlyModal/ExlyModal";
import { useOfferingFeedbackSettingsModal } from "./utils/useOfferingFeedbackSettingsModal";
import { SectionUnlockingSwitch } from "features/AdminPanel/modules/SectionUnlockingSwitch/SectionUnlockingSwitch";

export const OfferingFeedbackSettingsModalForm = () => {
  const { listing_uuid } = useParams();
  const {
    closeOfferingFeedbackSettingsModal,
    offeringFeedbackSettingsModalOpen,
  } = useOfferingFeedbackSettingsModal();
  const sectionConfig = useAdminPanelStore((state) => state.sectionConfig);
  const subSectionConfig = sectionConfig[FEEDBACK_SETTINGS_KEY] || {};

  const { value: selectedValue } = subSectionConfig;
  const [selectedValueState, setSelectedValueState] = useState(selectedValue);

  const { handleSectionChange } = useAccountSectionUnlocking();

  const handleSubmit = () => {
    const payload = {
      listing_uuid,
      feature_key: FEEDBACK_SETTINGS_KEY,
      feature_value: selectedValueState,
    };
    handleSectionChange({
      endPoint: UPDATE_ADMIN_PANEL_SETTINGS_LISITNG_LEVEL,
      payload,
    });
    closeOfferingFeedbackSettingsModal();
  };

  const onChange = () => {
    setSelectedValueState((prev) => !prev);
  };
  return (
    <ExlyModal
      title="Settings"
      header="Settings"
      primaryBtnText="Save changes"
      onPrimaryBtnClick={handleSubmit}
      primaryBtnProps={{ disabled: selectedValue === selectedValueState }}
      open={offeringFeedbackSettingsModalOpen}
      onClose={closeOfferingFeedbackSettingsModal}
      padded={false}
      showSecondaryBtn={true}
      modal_props={{
        modalPaperClassName: "tw-w-[429px]",
        secondaryBtnClassName: "!tw-text-[#493ab1] !tw-min-w-[unset]",
        footerClassName: "tw-flex !tw-justify-between p-4 h-auto",
      }}
      mobile_modal_props={{
        secondaryAction: {
          label: "Cancel",
          onClick: closeOfferingFeedbackSettingsModal,
          className: "!tw-text-[#493ab1] tw-min-w-[unset]",
        },
      }}
    >
      <div className={"tw-flex tw-items-start tw-p-[20px]"}>
        <div className="d-flex flex-column">
          <div
            className={
              "tw-text-[rgba(39,37,34,0.86)] tw-text-[16px] !font-[500] mb-1"
            }
          >
            Disable customer feedback
          </div>
          <div className="tw-text-[rgba(39,37,34,0.6)] tw-text-[14px] tw-font-normal tw-leading-[18px] tw-tracking-[0.28px]">
            Toggle on if you prefer not to send offering feedback links to
            customers.
          </div>
        </div>
        <SectionUnlockingSwitch
          value={selectedValueState}
          onChange={onChange}
        />
      </div>
    </ExlyModal>
  );
};
