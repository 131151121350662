export const DOMAIN_LINKING_INTEGRATION_TABS = {
  GUIDE: 1,
  SETUP: 2,
  DISCONNECTION: 3,
};

const DOMAIN_LINKING_INTEGRATION_TAB_LABELS = {
  [DOMAIN_LINKING_INTEGRATION_TABS.GUIDE]: "Guide",
  [DOMAIN_LINKING_INTEGRATION_TABS.SETUP]: "Setup",
  [DOMAIN_LINKING_INTEGRATION_TABS.DISCONNECTION]: "Disconnection",
};

export const DOMAIN_LINKING_INTEGRATION_TAB_OPTIONS = [
  {
    label:
      DOMAIN_LINKING_INTEGRATION_TAB_LABELS[
        DOMAIN_LINKING_INTEGRATION_TABS.GUIDE
      ],
    value: DOMAIN_LINKING_INTEGRATION_TABS.GUIDE,
  },
  {
    label:
      DOMAIN_LINKING_INTEGRATION_TAB_LABELS[
        DOMAIN_LINKING_INTEGRATION_TABS.SETUP
      ],
    value: DOMAIN_LINKING_INTEGRATION_TABS.SETUP,
  },
  {
    label:
      DOMAIN_LINKING_INTEGRATION_TAB_LABELS[
        DOMAIN_LINKING_INTEGRATION_TABS.DISCONNECTION
      ],
    value: DOMAIN_LINKING_INTEGRATION_TABS.DISCONNECTION,
  },
];

export const DOMAIN_PRE_LINKING_STEPS = {
  ADD_DOMAIN: 0,
  SAVE_DNS_RECORDS: 1,
  NAME_SERVERS_SETUP: 2,
  NAME_SERVERS_VERIFICATION: 3,
  INITIATE_DOMAIN_LINKING: 4,
  DOMAIN_LINKING_INITIATED: 5,
  DOMAIN_LINKING_COMPLETED: 6,
};

export const DOMAIN_LINKING_FLAGS = {
  ADD_DOMAIN: 5,
  SAVE_DNS_INFO: 6,
  ADD_NAME_SERVER_INFO: 7,
  NAMESERVERS_VERIFIED: 20,
  COMPLETED: 100,
};

export const DOMAIN_LINKING_LOCAL_STORAGE_KEYS = {
  // the step stored in this key needs to be maintained on the front-end
  // currently, only SAVE_DNS_INFO and ADD_NAME_SERVER_INFO steps are handled on the front-end
  current_completed_step: "domain_linking_current_completed_step",
  refresh_domain_details: "refresh_domain_details",
};
