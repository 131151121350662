import { matchPath } from "react-router";
import { api } from "data";
import {
  MANAGE_OFFERINGS_ROUTE_IDS,
  MANAGE_OFFERINGS_ROUTE_KEYS,
} from "features/ManageOfferings/constants/ManageOfferings.urlPaths";

export const offering_feedback_form_resource_apis = {
  [MANAGE_OFFERINGS_ROUTE_IDS[
    MANAGE_OFFERINGS_ROUTE_KEYS.OFFERING_FEEDBACK_FORM
  ]]: ({ pathname }) => {
    const matchResults = matchPath(pathname, {
      path: MANAGE_OFFERINGS_ROUTE_IDS[
        MANAGE_OFFERINGS_ROUTE_KEYS.OFFERING_FEEDBACK_FORM
      ],
    });
    return {
      api_end_point: api.feedbacks.fetch_listing_feedbacks,
      queries: {
        uuid: matchResults?.params?.listing_uuid,
      },
    };
  },
};
