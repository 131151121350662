import { fetchCreatorConfig } from "features/Common/modules/CreatorData/utils/CreatorData.utils";
import { checkIsArrayEmpty } from "features/Common/modules/DataTypes/utils/Array.utils";
import { checkIsDefined } from "features/Common/modules/DataTypes/utils/nanNullUndef";
import { LOCAL_STORAGE_KEYS } from "features/Common/modules/Storage/modules/LocalStorage/constants/LocalStorage.constants";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "features/Common/modules/Storage/modules/LocalStorage/utils/LocalStorage.utils";
import { useEffect } from "react";
import { logError } from "utils/error";
import { useToggleState } from "utils/hooks";
import { DOMAIN_LINKING_FLAGS } from "../constants/DomainLinking.constants";
import { getDomainDetails } from "./getDomainDetails";

// This hook updates the creator's website URL on app load, assisting in the domain linking process
export const useRefetchDomainDetailsOnMount = () => {
  const [isLoading, startLoading, stopLoading] = useToggleState(true);

  useEffect(() => {
    const handleRefetchDomainDetails = async () => {
      const shouldRefreshDomainDetailsFlagValue =
        getLocalStorageItem(LOCAL_STORAGE_KEYS.refresh_domain_details) ||
        undefined;

      /**
       * Refetch the creator's details if LOCAL_STORAGE_KEYS.refresh_domain_details
       * is either missing or set to 'true'.
       *
       * Refetch occurs in the following cases:
       *  1. First time after login.
       *  2. When domain linking is initiated: continues refetching on app load
       *     until domain linking is completed.
       */
      const shouldRefetch = checkIsDefined(shouldRefreshDomainDetailsFlagValue)
        ? shouldRefreshDomainDetailsFlagValue === "true"
        : true;

      if (shouldRefetch) {
        try {
          startLoading();
          await fetchCreatorConfig();

          const domainDetailsResponse = await getDomainDetails();
          const customDomains =
            domainDetailsResponse?.data?.data?.custom_domains;

          if (checkIsArrayEmpty(customDomains)) {
            setLocalStorageItem(
              LOCAL_STORAGE_KEYS.refresh_domain_details,
              false
            );
          } else {
            const [customDomainDetails] = customDomains;
            const { manual_linking_step: manualLinkingStep } =
              customDomainDetails;

            /**
             * Prevent refetching on app load in the following cases:
             *  1. When domain linking has not yet started.
             *  2. When domain linking is already completed.
             */
            if (
              manualLinkingStep <= DOMAIN_LINKING_FLAGS.NAMESERVERS_VERIFIED ||
              manualLinkingStep === DOMAIN_LINKING_FLAGS.COMPLETED
            ) {
              setLocalStorageItem(
                LOCAL_STORAGE_KEYS.refresh_domain_details,
                false
              );
            }
          }
        } catch (error) {
          logError({
            error,
            occuredAt:
              "src/features/Integration/modules/DomainLinking/utils/useRefetchDomainDetailsOnMount.js",
            when: "refetching domain details",
            customErrorMessage: error?.response?.data?.message,
            severity: "critical",
          });
        } finally {
          stopLoading();
        }
      } else {
        stopLoading();
      }
    };

    handleRefetchDomainDetails();
  }, []);

  return { isLoading };
};
